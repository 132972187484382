import React from 'react';

const Header = () => {
    return (
        <div className='PrivacyNotice-header'>
            <h1>
                <b>GDPR Privacy Statement</b>
            </h1>
            <h2>Nujoji Calvocoressi Psychotherapy</h2>
        </div>
    );
};
export default Header;
