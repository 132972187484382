import React from 'react';

export default {
    id: 'Thegriffinssociety2',
    cls: 'Thegriffinssociety2',
    featured: false,
    category: 'Blog article',
    title: 'No one will want to be my friend because I am a murderer...',
    summary: '',
    date: '2024',
    author: 'Sophie Crilly & Nujoji Calvocoressi',
    publication: 'The Griffins Society',
    sourceUrl:
        'https://www.thegriffinssociety.org/no-one-will-want-be-my-friend-because-i-am-murderer-exploration-experience-change-women-convicted',
    img: 'lonely-min.jpg',
    logo: 'griffins.png',
    content: (
        <>
            <h4 className='article-subtitle'>
                No one will want to be my friend because I am a murderer - An
                exploration into the experience of change in women convicted of
                murder and who have participated in the DTC intervention at HM
                Prison Send.
            </h4>
            <p>
                This research explores the experiences of five women convicted
                of murder who have participated in the Democratic Therapeutic
                Community (DTC) intervention at HM Prison Send. The study aimed
                to understand the experience of changing sense of self and
                identity. A subsidiary aim is to understand the mechanisms that
                facilitated the reported change by paying attention to the
                participant’s subjective experience and the objective external
                constructs. This study employs the Interpretative
                Phenomenological Analysis (IPA) methodology. Participants who
                completed or partially completed the intervention reported
                positive changes relating to their sense of self, identity, and
                hopes for a crime-free future. However, participants described
                feeling overwhelmed during their participation and encountering
                numerous obstacles. Notably, participants reported that the
                positive change was not always identifiable until after they
                left the DTC. This study contributes insights into the
                distinctive contextual factors reflected in the women's
                experiences.
            </p>
        </>
    ),
    comments: [],
};
